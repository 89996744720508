console.log("JS works.");

var template = document.body.getAttribute('data-template') || document.body.getAttribute('data-intended-template');

// subnav observer
var subnav = document.querySelector('.subnav');
var title = document.querySelector('.page-title');

if(subnav){
  var handler = function(entries) {
    if (!entries[0].isIntersecting) {
      subnav.classList.add('stuck');
    } else {
      subnav.classList.remove('stuck');
    }
  };
  var observer = new window.IntersectionObserver(handler);
  observer.observe(title);
}


// hamburger

var hamburger = document.querySelector('.hamburger');
var menu = document.querySelector('.main-nav');
hamburger.addEventListener('click', function(){
  this.classList.toggle('is-active');
  menu.classList.toggle('open');
});


// books
var books = document.querySelector(".books");

var Bok = function (el) {
  
  var timeout = null;
  var clearto = function() {
    clearTimeout(timeout);
    timeout = null;
  };  
  var src = el.dataset.image;
  var figure = el.querySelector('figure');

  var loadimage = function(){
    timeout = setTimeout(function(){
      var img = new Image(); 
      el.classList.add('rollover');
      img.addEventListener('load', function() {
        el.classList.add('loaded');
        figure.style.backgroundImage = "url(" + src + ')';
      }, false);
      img.src = src;   
    }, 500);
  };

  this.onmouseover = function(){
    if(el.classList.contains('loaded')){
      el.classList.add('rollover');
    } else {      
      el.classList.add('loading');
      loadimage();
    }
  };
  this.onmouseout = function(){
    el.classList.remove('rollover');
    el.classList.remove('loading');
    clearto();
  };
};


if(books){
  var bks = document.querySelectorAll('.book');
  for(var i= 0; i<bks.length; i++){
    var bok = new Bok(bks[i]);
    bks[i].onmouseover = bok.onmouseover;
    bks[i].onmouseout = bok.onmouseout;
  }
}









// authors
if(template == "authors"){
  var authors = document.querySelector('.authors');
  authors.addEventListener('click', function(e){
    var el = e.target;
    if( el.matches('.author')){
      el.parentNode.classList.toggle('open');
    }
  });
}

// news
if(template == "news"){
  var authors = document.querySelector('.news');
  authors.addEventListener('click', function(e){
    var el = e.target;
    if( el.matches('button')){
      var parent = el.parentNode;
      if(parent.classList.contains('open')){
        el.textContent = el.getAttribute('data-opencontent');
      } else {
        el.textContent = el.getAttribute('data-closecontent');
      }
      el.parentNode.classList.toggle('open');
    }
  });
}


// book
if(template == "book"){
  var cover = document.querySelector('.book-cover');
  cover.addEventListener('click', function(e){
    var el = e.target;
    var parent = el.closest('figure');
    var button = parent.querySelector('.coverlink');
    var href = button.href;
    var hidetxt = button.getAttribute('data-hide-img');
    var showtxt = button.getAttribute('data-show-img');
    var img = parent.querySelector('img');

    if(el.matches('.alwayslink')){
      return true;
    }

    if( el.matches('.coverlink') || el.matches('rect') || el.matches('img') ){
      e.preventDefault();
      if(parent.classList.contains('show-img')){
        button.textContent = hidetxt;
        parent.classList.toggle('show-img');
        parent.classList.toggle('no-img');
      } else {
        var i = new Image(); 
        i.addEventListener('load', function() {
          img.src = href;
          parent.classList.toggle('show-img');
          parent.classList.toggle('no-img');
        }, false);
        i.src = href;  
        button.textContent = showtxt;
      }
      
    }
  });

  // book observer
  var header = document.querySelector('.book-header');
  if(cover){
    var bookhandler = function(entries) {
      if (!entries[0].isIntersecting) {
        cover.classList.add('show-title');
      } else {
        cover.classList.remove('show-title');
      }
    };
    var observer = new window.IntersectionObserver(bookhandler);
    observer.observe(header);
  }

}


